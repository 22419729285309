// These helpers are calling FTW's own server-side routes
// so, they are not directly calling Marketplace API or Integration API.
// You can find these api endpoints from 'server/api/...' directory

import { types as sdkTypes, transit } from './sdkLoader';
import config from '../config';
import Decimal from 'decimal.js';

export const apiBaseUrl = () => {
  const port = process.env.REACT_APP_DEV_API_SERVER_PORT;
  const useDevApiServer = process.env.NODE_ENV === 'development' && !!port;

  // In development, the dev API server is running in a different port
  if (useDevApiServer) {
    return `http://localhost:${port}`;
  }

  // Otherwise, use the same domain and port as the frontend
  return `${window.location.origin}`;
};

// Application type handlers for JS SDK.
//
// NOTE: keep in sync with `typeHandlers` in `server/api-util/sdk.js`
export const typeHandlers = [
  // Use Decimal type instead of SDK's BigDecimal.
  {
    type: sdkTypes.BigDecimal,
    customType: Decimal,
    writer: v => new sdkTypes.BigDecimal(v.toString()),
    reader: v => new Decimal(v.value),
  },
];

const serialize = data => {
  return transit.write(data, { typeHandlers, verbose: config.sdk.transitVerbose });
};

const deserialize = str => {
  return transit.read(str, { typeHandlers });
};

export const post = (path, body) => {
  const url = `${apiBaseUrl()}${path}`;
  const options = {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/transit+json',
    },
    body: serialize(body),
  };
  return window.fetch(url, options).then(res => {
    const contentTypeHeader = res.headers.get('Content-Type');
    const contentType = contentTypeHeader ? contentTypeHeader.split(';')[0] : null;

    if (res.status >= 400) {
      return res.json().then(data => {
        let e = new Error();
        e = Object.assign(e, data);

        throw e;
      });
    }
    if (contentType === 'application/transit+json') {
      return res.text().then(deserialize);
    } else if (contentType === 'application/json') {
      return res.json();
    }
    return res.text();
  });
};

// Fetch transaction line items from the local API endpoint.
//
// See `server/api/transaction-line-items.js` to see what data should
// be sent in the body.
export const transactionLineItems = body => {
  return post('/api/transaction-line-items', body);
};

// Initiate a privileged transaction.
//
// With privileged transitions, the transactions need to be created
// from the backend. This endpoint enables sending the booking data to
// the local backend, and passing that to the Marketplace API.
//
// See `server/api/initiate-privileged.js` to see what data should be
// sent in the body.
export const initiatePrivileged = body => {
  return post('/api/initiate-privileged', body);
};

// Transition a transaction with a privileged transition.
//
// This is similar to the `initiatePrivileged` above. It will use the
// backend for the transition. The backend endpoint will add the
// payment line items to the transition params.
//
// See `server/api/transition-privileged.js` to see what data should
// be sent in the body.
export const transitionPrivileged = body => {
  return post('/api/transition-privileged', body);
};

// Create user with identity provider (e.g. Facebook or Google)
//
// If loginWithIdp api call fails and user can't authenticate to Flex with idp
// we will show option to create a new user with idp.
// For that user needs to confirm data fetched from the idp.
// After the confirmation, this endpoint is called to create a new user with confirmed data.
//
// See `server/api/auth/createUserWithIdp.js` to see what data should
// be sent in the body.
export const createUserWithIdp = body => {
  return post('/api/auth/create-user-with-idp', body);
};

export const checkIfCustomBooking = body => {
  return post('/api/booking/check-trx', body);
};
export const createBookingRequest = body => {
  return post('/api/booking/request', body);
};
export const getBookingTransactionPageDetails = body => {
  return post('/api/booking/details', body);
};
export const getInboxTransactionDetails = body => {
  return post('/api/booking/inbox', body);
};
export const acceptBooking = body => {
  return post('/api/booking/accept', body);
};
export const declineBooking = body => {
  return post('/api/booking/decline', body);
};
export const updateBookingPaymentMethod = body => {
  return post('/api/booking/update-payment-method', body);
};

export const acceptAutoRenewal = body => {
  return post('/api/auto-renewal/accept', body);
};
export const declineAutoRenewal = body => {
  return post('/api/auto-renewal/decline', body);
};

export const getPaymentMethodsList = body => {
  return post('/api/payment-method/list', body);
};
export const retrievePaymentMethod = body => {
  return post('/api/payment-method/retrieve', body);
};
export const retrieveBookingPaymentMethod = body => {
  return post('/api/payment-method/retrieve-booking', body);
};
export const attachPaymentMethod = body => {
  return post('/api/payment-method/attach', body);
};
export const detachPaymentMethod = body => {
  return post('/api/payment-method/detach', body);
};

export const createSetupIntent = body => {
  return post('/api/setup-intent', body);
};

export const updateUserProfileDetails = () => {
  return post('/api/user/update-profile-details', {});
};
export const updateUserContactDetails = () => {
  return post('/api/user/update-contact-details', {});
};

export const getTaxInfo = () => {
  return post('/api/tax-info/details', {});
};
export const insertTaxInfo = body => {
  return post('/api/tax-info/insert', body);
};
export const updateTaxInfo = body => {
  return post('/api/tax-info/update', body);
};

export const fetchArtworkFiles = body => {
  return post('/api/artwork-file/fetch-files', body);
};
export const saveArtworkFiles = body => {
  return post('/api/artwork-file/save-files', body);
};

export const checkIfCancelAllowed = body => {
  return post('/api/booking-cancel/check-if-cancel-allowed', body);
};
export const getRefundAmounts = body => {
  return post('/api/booking-cancel/get-refund-amounts', body);
};
export const beginCancellation = body => {
  return post('/api/booking-cancel/begin-cancellation', body);
};

export const getCustomOffers = body => {
  return post('/api/custom-offer/get', body);
};
export const getCustomOfferTrxLineItems = body => {
  return post('/api/custom-offer/get-trx-line-items', body);
};
export const createCustomOffer = body => {
  return post('/api/custom-offer/create', body);
};
export const withdrawalCustomOffer = body => {
  return post('/api/custom-offer/withdrawal', body);
};
export const acceptCustomOffer = body => {
  return post('/api/custom-offer/accept', body);
};

export const sendContactEmail = body => {
  return post('/api/contact/send-contact-email', body);
};

export const retrieveStripeAccount = body => {
  return post('/api/stripe-account/retrieve-stripe-account', body);
};
export const connectStripeAccount = body => {
  return post('/api/stripe-account/connect-stripe-account', body);
};
export const createStripeAccountLoginLink = body => {
  return post('/api/stripe-account/create-stripe-account-login-link', body);
};
export const createStripeAccountLink = body => {
  return post('/api/stripe-account/create-stripe-account-link', body);
};