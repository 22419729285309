import React from 'react';
import { FormattedDate, FormattedMessage, intlShape } from '../../util/reactIntl';
import { LINE_ITEM_DAY, LINE_ITEM_SUBSCRIPTION_FIRST_MONTH, propTypes } from '../../util/types';
import subscriptionCss from './subscription/Subscription.module.scss';
import css from './BookingBreakdown.module.css';
import dateFormatOptions from '../../util/dateFormatOptions';
import { formatMoney } from '../../util/currency';

const LineItemBaseOrSubscriptionFirstMonthPrice = props => {
  const {
    transaction,
    intl,
  } = props;

  const subscriptionFirstMonth = transaction.attributes.lineItems.find(
    item => item.code === LINE_ITEM_SUBSCRIPTION_FIRST_MONTH && !item.reversal
  );
  const unitPurchase = subscriptionFirstMonth ? subscriptionFirstMonth : transaction.attributes.lineItems.find(
    item => item.code === LINE_ITEM_DAY && !item.reversal
  );

  const translationKey = subscriptionFirstMonth ? 'BookingBreakdown.subscriptionFirstMonth' : 'BookingBreakdown.baseUnitDay';
  const unitPriceLabel = unitPurchase ? formatMoney(intl, unitPurchase.unitPrice) : null;
  const totalLabel = unitPurchase ? formatMoney(intl, unitPurchase.lineTotal) : null;

  return totalLabel ? (
    <>
      {subscriptionFirstMonth &&
        <div className={subscriptionCss.basePriceLabelWrapper}>
          <h2>
            <FormattedMessage id="LineItemBaseOrSubscriptionFirstMonthPrice.label" />
          </h2>
          <p>
            <FormattedDate value={unitPurchase.start} {...dateFormatOptions} /> -{' '}
            <FormattedDate value={unitPurchase.end} {...dateFormatOptions} />
          </p>
        </div>
      }
      <div className={css.lineItem}>
        <span className={css.itemLabel}>
          {subscriptionFirstMonth ? (
            <FormattedMessage
              id={translationKey}
              values={{
                unitPrice: unitPriceLabel,
                quantity: unitPurchase.quantity
              }}
            />
          ) : (
            <FormattedMessage
              id={translationKey}
              values={{
                unitPrice: unitPriceLabel,
                units: unitPurchase.units,
                seats: unitPurchase.seats
              }}
            />
          )}
        </span>
        <span className={css.itemValue}>
          {totalLabel}
        </span>
      </div>
    </>
  ) : null;
};

LineItemBaseOrSubscriptionFirstMonthPrice.propTypes = {
  transaction: propTypes.transaction.isRequired,
  unitType: propTypes.bookingUnitType.isRequired,
  intl: intlShape.isRequired,
  isCheckout: false,
};

export default LineItemBaseOrSubscriptionFirstMonthPrice;
