import React, { useEffect, useState } from 'react';
import { getDateLabel, getSubscriptionIntervals, getTotalForDueLater } from './utils';
import config from '../../../config';
import { toMomentDate } from '../../../util/dates';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { formatMoney } from '../../../util/currency';
import { manageDisableScrolling } from '../../../ducks/UI.duck';
import { useDispatch } from 'react-redux';
import Modal from '../../Modal/Modal';
import PaymentScheduleBreakdown from '../../PaymentScheduleBreakdown/PaymentScheduleBreakdown';
import { LINE_ITEM_DISCOUNT, LINE_ITEM_SUBSCRIPTION_FIRST_MONTH } from '../../../util/types';
import dateFormatOptions from '../../../util/dateFormatOptions';

import bookingBreakdownCss from '../BookingBreakdown.module.css';
import css from './Subscription.module.scss';

const { types } = require('sharetribe-flex-sdk');
const { Money } = types;

const SubscriptionDueLaterLineItems = props => {
  const {
    isSubscription,
    booking,
    bookingQuantity,
    transaction,
    intl,
    isCustomer,
    isProvider,
    listing,
    setLineItemsSubscription
  } = props;

  const subscriptionFirstMonth = transaction.attributes.lineItems.find(
    item => item.code === LINE_ITEM_SUBSCRIPTION_FIRST_MONTH && !item.reversal
  );

  if (!subscriptionFirstMonth) {
    return null;
  }

  // const { end } = booking.attributes;
  const end = toMomentDate(booking.attributes.end);
  const start = toMomentDate(booking.attributes.start);

  const lastTransition = transaction.attributes?.lastTransition;
  const showPaymentStatus = (
    lastTransition === 'transition/confirm-payment' ||
    lastTransition === 'transition/request-payment' ||
    !lastTransition
  ) ? false : true;

  if (!isSubscription || !start || !end) {
    return null;
  }

  const discountLineItem = transaction.attributes.lineItems.find(
    item => item.code === LINE_ITEM_DISCOUNT
  );
  let discountPercentage = null;
  if (discountLineItem) {
    const percentage = discountLineItem.percentage;
    discountPercentage = parseFloat(percentage);
  }

  const [subscriptionPayments] = useState(
    transaction.attributes?.subscriptionPayments ? transaction.attributes?.subscriptionPayments :
      getSubscriptionIntervals(
        start,
        end,
        transaction,
        bookingQuantity,
        listing,
        0,
        discountPercentage
      )
  );

  const [subscriptionTotal] = useState(
    transaction.attributes?.subscriptionTotal ? transaction.attributes?.subscriptionTotal :
      getTotalForDueLater(
        transaction,
        bookingQuantity,
        listing,
        null,
        null,
        discountPercentage
      )
  );

  useEffect(() => {
    if (subscriptionPayments && subscriptionTotal) {
      if (setLineItemsSubscription) {
        setLineItemsSubscription(subscriptionPayments, subscriptionTotal);
      }
    }
  }, [subscriptionPayments, subscriptionTotal]);

  const dispatch = useDispatch();
  const onManageDisableScrolling = (componentId, disableScrolling) => {
    dispatch(manageDisableScrolling(componentId, disableScrolling));
  };

  const [paymentScheduleModalOpen, setPaymentScheduleModalOpen] = useState(false);

  const formatIntegerToMoney = (amount) => {
    if (amount) {
      return formatMoney(intl, new Money(amount, config.currency))
    } else {
      return '';
    }
  };

  const headerDate = subscriptionPayments.length > 0 ? getDateLabel(toMomentDate(subscriptionPayments[0].start)) : null;

  return (subscriptionPayments.length > 0 &&
    <>
      <div className={css.dueLaterWrapper}>
        <hr className={css.totalDivider} />
        <h2><FormattedMessage id="SubscriptionDueLaterLineItems.title" values={{ headerDate }} /></h2>
        <div className={css.totalWrapper}>
          <span className={bookingBreakdownCss.itemLabel}>
            <span>
              <FormattedDate value={subscriptionPayments[0].startLabel} {...dateFormatOptions}
              />
            </span>
            <span> - </span>
            <span>
              <FormattedDate value={subscriptionPayments?.[subscriptionPayments.length - 1].endLabel} {...dateFormatOptions} />
            </span>
            <br />
            <span className={css.subscriptionDateRange}>
              <FormattedMessage
                id="SubscriptionDueLaterLineItems.basePriceBreakdown"
                values={{
                  subscriptionBaseAmount: formatIntegerToMoney(subscriptionTotal.subscriptionBaseAmount),
                  payments: subscriptionPayments.length,
                  seats: subscriptionTotal?.seats
                }}
              />
            </span>
          </span>
          <span className={bookingBreakdownCss.itemValue}>
            {formatIntegerToMoney(subscriptionTotal?.amountLessTax)}
          </span>
        </div>
        {subscriptionTotal?.discountAmount && subscriptionTotal?.discountAmount !== 0 ? (
          <div className={bookingBreakdownCss.lineItem}>
            <span className={bookingBreakdownCss.itemLabel}>
              <FormattedMessage id="SubscriptionDueLaterLineItems.discount" />
              {(subscriptionTotal?.discountPercentage && subscriptionTotal?.discountPercentage !== 0) &&
                <>
                  {' '}
                  <FormattedMessage
                    id="SubscriptionDueLaterLineItems.discountPercentage"
                    values={{ discountPercentage: subscriptionTotal.discountPercentage }}
                  />
                </>
              }
            </span>
            <span className={bookingBreakdownCss.itemValue}>
              {formatIntegerToMoney(subscriptionTotal.discountAmount)}
            </span>
          </div>
        ) : (null)}
        {subscriptionTotal?.installationFeeAmount && subscriptionTotal?.installationFeeAmount !== 0 ? (
          <div className={bookingBreakdownCss.lineItem}>
            <span className={bookingBreakdownCss.itemLabel}><FormattedMessage id="SubscriptionDueLaterLineItems.installationFee" /></span>
            <span className={bookingBreakdownCss.itemValue}>
              {formatIntegerToMoney(subscriptionTotal.installationFeeAmount)}
            </span>
          </div>
        ) : (null)}
        <div className={bookingBreakdownCss.lineItem}>
          <span className={bookingBreakdownCss.itemLabel}><FormattedMessage id="SubscriptionDueLaterLineItems.subtotal" /></span>
          <span className={bookingBreakdownCss.itemValue}>
            {formatIntegerToMoney(subscriptionTotal?.subtotal)}
          </span>
        </div>
        <div className={bookingBreakdownCss.lineItem}>
          <span className={bookingBreakdownCss.itemLabel}><FormattedMessage id="SubscriptionDueLaterLineItems.tax" /></span>
          <span className={bookingBreakdownCss.itemValue}>
            {formatIntegerToMoney(subscriptionTotal?.taxAmount)}
          </span>
        </div>
        {isCustomer &&
          <>
            <div className={bookingBreakdownCss.lineItem}>
              <span className={bookingBreakdownCss.itemLabel}><FormattedMessage id="SubscriptionDueLaterLineItems.adloFee" /></span>
              <span className={bookingBreakdownCss.itemValue}>
                {formatIntegerToMoney(subscriptionTotal?.customerFeeAmount)}
              </span>
            </div>
            <div className={bookingBreakdownCss.lineItem}>
              <span className={bookingBreakdownCss.itemLabel}><FormattedMessage id="SubscriptionDueLaterLineItems.taxOnAdloFee" /></span>
              <span className={bookingBreakdownCss.itemValue}>
                {formatIntegerToMoney(subscriptionTotal?.taxOnCustomerCommission)}
              </span>
            </div>
            <hr className={bookingBreakdownCss.totalDivider} />
            <div className={bookingBreakdownCss.lineItemTotal}>
              <span className={bookingBreakdownCss.totalLabel}><FormattedMessage id="SubscriptionDueLaterLineItems.totalDueLater" /></span>
              <span className={bookingBreakdownCss.totalPrice}>
                {formatIntegerToMoney(subscriptionTotal?.customerAmount)}
              </span>
            </div>
          </>
        }
        {isProvider &&
          <>
            <hr className={bookingBreakdownCss.totalDivider} />
            <div className={bookingBreakdownCss.lineItemTotal}>
              <span className={bookingBreakdownCss.totalLabel}>
                <FormattedMessage id="SubscriptionDueLaterLineItems.totalDueLater" />
              </span>
              <span className={bookingBreakdownCss.totalPrice}>
                {formatIntegerToMoney(subscriptionTotal?.subtotalPlusTax)}
              </span>
            </div>
          </>
        }
        <hr className={bookingBreakdownCss.totalDivider} />
        {/* <div className={css.dueLaterProviderBreakdown}>
          <h6>Payout breakdown</h6>
          <div className={bookingBreakdownCss.lineItem}>
            <span className={bookingBreakdownCss.itemLabel}><FormattedMessage id="SubscriptionDueLaterLineItems.adloFee" /></span>
            <span className={bookingBreakdownCss.itemValue}>
              {formatIntegerToMoney(subscriptionTotal?.providerFeeAmount)}
            </span>
          </div>
          <div className={bookingBreakdownCss.lineItem}>
            <span className={bookingBreakdownCss.itemLabel}><FormattedMessage id="SubscriptionDueLaterLineItems.taxOnAdloFee" /></span>
            <span className={bookingBreakdownCss.itemValue}>
              {formatIntegerToMoney(subscriptionTotal?.taxOnProviderCommission)}
            </span>
          </div>
          <div className={bookingBreakdownCss.lineItemTotal}>
            <span className={bookingBreakdownCss.totalLabel}>
              You'll make
            </span>
            <span className={bookingBreakdownCss.totalPrice}>
              {formatIntegerToMoney(subscriptionTotal?.providerAmount)}
            </span>
          </div>
        </div> */}
        <Modal
          id="SubscriptionDueLaterLineItems.paymentScheduleModal"
          isOpen={paymentScheduleModalOpen}
          onClose={() => {
            setPaymentScheduleModalOpen(false);
          }}
          usePortal={true}
          onManageDisableScrolling={onManageDisableScrolling}>
          <PaymentScheduleBreakdown
            subscriptionPayments={subscriptionPayments}
            subscriptionTotal={subscriptionTotal}
            isProvider={isProvider}
            isAutoRenewal={false}
            showPaymentStatus={showPaymentStatus}
          />
        </Modal>
      </div>
      <div className={css.rowUnsetMarginLR}>
        <a className={css.viewPaymentScheduleLink}
          onClick={() => setPaymentScheduleModalOpen(true)}>
          <FormattedMessage id="SubscriptionDueLaterLineItems.viewPaymentScheduleLink" />
        </a>
      </div>
    </>
  );
};

export default SubscriptionDueLaterLineItems;
