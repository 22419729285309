import ClientAccountingUtil from '../../../util/client-accouting.util';
import { daysBetween } from '../../../util/dates';
import { CUSTOMER_COMMISSION_PERCENTAGE, LINE_ITEM_SUBSCRIPTION_FIRST_MONTH, LINE_ITEM_SUBTOTAL, PROVIDER_COMMISSION_PERCENTAGE, SUBSCRIPTION_MONTH_DAYS_LIMIT, TAX_RATE_HST } from '../../../util/types';
import { Decimal } from 'decimal.js';
import moment from 'moment';

Date.prototype.addDays = function (days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};

Date.prototype.removeDays = function (days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() - days);
  return date;
};

export const getDateLabel = date => {
  const workingDate = date.getDate();
  switch (workingDate) {
    case 1:
      return workingDate + 'st';
    case 2:
      return workingDate + 'nd';
    case 3:
      return workingDate + 'rd';
    default:
      return workingDate + 'th';
  }
};

export const getSubscriptionIntervals = (
  start,
  end,
  transaction,
  bookingQuantity,
  listing,
  subscriptionInstallationFee = 0,
  discountPercentage = null,
) => {
  // console.log(`****************************************`);
  // console.log(`getSubscriptionIntervals start`, start);
  // console.log(`getSubscriptionIntervals end`, end);
  // console.log(`getSubscriptionIntervals transaction`, transaction);
  // console.log(`getSubscriptionIntervals bookingQuantity`, bookingQuantity);
  // console.log(`getSubscriptionIntervals unitType`, unitType);
  // console.log(`getSubscriptionIntervals listing`, listing);

  const unitPurchase = transaction.attributes.lineItems.find(
    item => item.code === LINE_ITEM_SUBSCRIPTION_FIRST_MONTH
  );

  const seatsQuantity = unitPurchase?.quantity ? unitPurchase.quantity : 1;

  const subscriptionPayments = [];
  let monthCount = 1;
  let testingEndDate = new Date(start);

  while (testingEndDate < end && bookingQuantity > 31) {
    //keep setting the interval start date to the next month on the same date
    const currentStartDate = new Date(start);
    currentStartDate.setMonth(start.getMonth() + monthCount);

    //set the interval end date to the second next month on the (currentStartDate date - 1)
    const testEndDate = new Date(start);
    testEndDate.setMonth(start.getMonth() + monthCount + 1);
    testEndDate.setDate(start.getDate());

    //if the calculated end date is greater than the booking end date, that means this is the last interval, so end date = booking end date
    const currentEndDate = testEndDate > end ? end : testEndDate;

    //because of the way ST calculates the booking, the value of end date is one day greater than the label for it
    const displayEndDate = currentEndDate.removeDays(1);

    const daysCount = daysBetween(
      currentStartDate,
      new Date(currentEndDate).setHours(currentStartDate.getHours()) //end date comes with one hour less than the start date, so we need to adjust
      //so we can get the correct days count
    );
    const daysCountLabel = daysCount === 1 ? daysCount + ' day' : daysCount + ' days';

    const unitPriceAmount = unitPurchase ? unitPurchase.unitPrice.amount : 0;

    //TODO remove if you want to calculate price according to daily price
    const listingObj = listing || transaction?.listing;
    const listingSubscriptionAmount = listingObj?.attributes?.publicData?.subscriptionPrice;
    // ---- end

    const priceAmount = listingSubscriptionAmount
      ? seatsQuantity * listingSubscriptionAmount
      : daysCount * seatsQuantity * unitPriceAmount;

    let discountAmount = 0;
    if (discountPercentage) {
      discountAmount = priceAmount * (discountPercentage / 100);
    }

    let installationFeeAmount = 0;
    if (monthCount === 1 && subscriptionInstallationFee > 0) {
      installationFeeAmount = subscriptionInstallationFee;
    }

    let subtotal = priceAmount;
    if (discountAmount) {
      subtotal = subtotal + discountAmount;
    }
    if (installationFeeAmount) {
      subtotal = subtotal + installationFeeAmount;
    }

    const authorPublicData = listing.author.attributes.profile.publicData;
    const finalProviderCommission = authorPublicData?.customProviderCommission ?
      ClientAccountingUtil.convertToNegativeNumber(authorPublicData.customProviderCommission)
      : PROVIDER_COMMISSION_PERCENTAGE;
    const providerCommissionAmount = subtotal * (finalProviderCommission / 100);
    const taxOnProviderCommissionAmount = providerCommissionAmount * TAX_RATE_HST;

    const customerCommissionAmount = subtotal * (CUSTOMER_COMMISSION_PERCENTAGE / 100);
    const taxOnCustomerCommissionAmount = customerCommissionAmount * TAX_RATE_HST;

    const taxAmount = subtotal * TAX_RATE_HST;
    const subtotalPlusTax = subtotal + taxAmount;

    const customerTotalAmount = subtotal + taxAmount + customerCommissionAmount + taxOnCustomerCommissionAmount;
    const providerPayoutLessTaxAmount = subtotal + (providerCommissionAmount + taxOnProviderCommissionAmount);

    const discountPercentagePositive = discountPercentage ? ClientAccountingUtil.convertToPositiveNumber(discountPercentage) : null;

    subscriptionPayments.push({
      startLabel: currentStartDate,
      start: currentStartDate,
      endLabel: displayEndDate,
      end: currentEndDate,
      daysCount,
      daysCountLabel,
      priceAmount,
      discountAmount,
      installationFeeAmount,
      subtotal,
      taxAmount,
      subtotalPlusTax,
      customerCommissionAmount,
      taxOnCustomerCommissionAmount,
      customerTotalAmount,
      providerCommissionAmount,
      taxOnProviderCommissionAmount,
      providerPayoutLessTaxAmount,
      discountPercentage: discountPercentagePositive
    });

    monthCount += 1;
    testingEndDate = currentEndDate;
  }

  return subscriptionPayments.filter(s => s.daysCount > Number(SUBSCRIPTION_MONTH_DAYS_LIMIT));
};

export const getTotalForDueLater = (
  transaction,
  bookingQuantity,
  listing,
  currentSubscriptionPayments = null,
  subscriptionInstallationFee = null,
  discountPercentage = null,
) => {
  const unitPurchase = transaction.attributes.lineItems.find(
    item => item.code === LINE_ITEM_SUBSCRIPTION_FIRST_MONTH
  );

  const quantity = unitPurchase ? parseInt(unitPurchase.quantity) : 1;
  const seatsQuantity = quantity;

  let subscriptionPayments = [];
  if (currentSubscriptionPayments) {
    subscriptionPayments = currentSubscriptionPayments;
  } else {
    const { start, end } = transaction.booking.attributes;
    subscriptionPayments = getSubscriptionIntervals(
      start,
      end,
      transaction,
      bookingQuantity,
      listing
    );
  }

  const currentListing = listing || transaction?.listing;
  const listingSubscriptionAmount = currentListing?.attributes?.publicData?.subscriptionPrice;
  const subsMonthsNumber = subscriptionPayments.length;

  const subscriptionMonthsTotalAmountLessTax = (listingSubscriptionAmount * subsMonthsNumber * seatsQuantity);

  let discountAmount = 0;
  if (discountPercentage) {
    discountAmount = subscriptionMonthsTotalAmountLessTax * (discountPercentage / 100);
  }

  let subtotal = subscriptionMonthsTotalAmountLessTax;
  if (discountAmount) {
    subtotal = subtotal + discountAmount;
  }
  const installationFeeAmount = subscriptionInstallationFee ? subscriptionInstallationFee : 0;
  if (installationFeeAmount) {
    subtotal = subtotal + installationFeeAmount;
  }

  const taxAmount = subtotal * TAX_RATE_HST;
  const subtotalPlusTax = subtotal + taxAmount;

  const subscriptionMonthsTotalAmount = subtotal * (1 + TAX_RATE_HST);

  const authorPublicData = listing.author.attributes.profile.publicData;
  const finalProviderCommission = authorPublicData?.customProviderCommission ?
    ClientAccountingUtil.convertToNegativeNumber(authorPublicData.customProviderCommission)
    : PROVIDER_COMMISSION_PERCENTAGE;
  const subscriptionMonthsTotalAmountForProvider = subscriptionMonthsTotalAmount * (1 - finalProviderCommission / 100);
  const subscriptionTotalFeeAmountForProvider = subtotal * (finalProviderCommission / 100);
  const taxOnProviderCommission = subscriptionTotalFeeAmountForProvider * TAX_RATE_HST;

  const subscriptionMonthsTotalAmountForCustomer = subscriptionMonthsTotalAmount * (1 + CUSTOMER_COMMISSION_PERCENTAGE / 100);
  const subscriptionTotalFeeAmountForCustomer = subtotal * (CUSTOMER_COMMISSION_PERCENTAGE / 100);
  const taxOnCustomerCommission = subscriptionTotalFeeAmountForCustomer * TAX_RATE_HST;

  const discountPercentagePositive = discountPercentage ? ClientAccountingUtil.convertToPositiveNumber(discountPercentage) : null;

  const providerPayoutLessTaxAmount = subtotal + (subscriptionTotalFeeAmountForProvider + taxOnProviderCommission);

  return {
    amount: subscriptionMonthsTotalAmount,
    amountLessTax: subscriptionMonthsTotalAmountLessTax,
    discountAmount,
    installationFeeAmount,
    subtotal,
    taxAmount,
    subtotalPlusTax,
    providerAmount: subscriptionMonthsTotalAmountForProvider,
    providerFeeAmount: subscriptionTotalFeeAmountForProvider,
    providerPayoutLessTaxAmount,
    taxOnProviderCommission,
    customerAmount: subscriptionMonthsTotalAmountForCustomer,
    customerFeeAmount: subscriptionTotalFeeAmountForCustomer,
    taxOnCustomerCommission,
    subscriptionBaseAmount: listingSubscriptionAmount,
    seats: seatsQuantity,
    discountPercentage: discountPercentagePositive
  };
};

export const residualInterval = (start, end) => {
  const bookingQuantity = daysBetween(start, end);
  if (bookingQuantity > 31) {
    const subscriptionPayments = [];
    let monthCount = 1;
    let testingEndDate = new Date(start);

    while (testingEndDate < end && bookingQuantity > 31) {
      //keep setting the interval start date to the next month on the same date
      const currentStartDate = new Date(start);
      currentStartDate.setMonth(start.getMonth() + monthCount);

      //set the interval end date to the second next month on the (currentStartDate date - 1)
      const testEndDate = new Date(start);
      testEndDate.setMonth(start.getMonth() + monthCount + 1);
      testEndDate.setDate(start.getDate());

      //if the calculated end date is greater than the booking end date, that means this is the last interval, so end date = booking end date
      const currentEndDate = testEndDate > end ? end : testEndDate;

      //calculate days count
      const daysCount = daysBetween(currentStartDate, currentEndDate);

      monthCount += 1;
      testingEndDate = currentEndDate;

      subscriptionPayments.push({
        start: currentStartDate,
        end: currentEndDate,
        daysCount,
      });
    }

    const firstSubscriptionMonth = subscriptionPayments[0];
    const lastSubscriptionMonth =
      subscriptionPayments.length > 0 && subscriptionPayments[subscriptionPayments.length - 1];

    const momentStart = moment(lastSubscriptionMonth.start, 1, 'days').add(1, 'days');
    const serverDayStart =
      momentStart
        .startOf('day')
        .toDate()
    const serverDayEnd = moment(end).subtract(1, 'days').toDate();

    lastSubscriptionMonth.start = serverDayStart
    lastSubscriptionMonth.end = serverDayEnd;
    lastSubscriptionMonth.daysCount = daysBetween(lastSubscriptionMonth.start, lastSubscriptionMonth.end);

    // console.log(`lastSubscriptionMonth.start`, moment(lastSubscriptionMonth.start).format('LLLL'))
    // console.log(`lastSubscriptionMonth.end (original)`, moment(end).format('LLLL'))
    // console.log(`lastSubscriptionMonth.end`, moment(lastSubscriptionMonth.end).format('LLLL'))
    // console.log(`lastSubscriptionMonth`, lastSubscriptionMonth)

    const residualMonth =
      lastSubscriptionMonth.daysCount < Number(SUBSCRIPTION_MONTH_DAYS_LIMIT) && lastSubscriptionMonth;
    return {
      residualMonth,
      subscriptionFirstMonth: {
        start,
        end: moment(firstSubscriptionMonth.start).subtract(1, 'days').toDate()
      }
    };
  } else {
    const baseDayPrice = {
      daysCount: bookingQuantity,
      start,
      end:
        moment(end)
          .startOf('day')
          .toDate()
    }
    return { baseDayPrice };
  }
};

export const getSubtotalLineItem = subtotalMoneyAmount => {
  return {
    code: LINE_ITEM_SUBTOTAL,
    includeFor: ['customer', 'provider'],
    unitPrice: subtotalMoneyAmount,
    lineTotal: subtotalMoneyAmount,
    quantity: new Decimal(1),
    reversal: false
  };
};