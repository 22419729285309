import React from 'react';
import moment from 'moment';
import Decimal from 'decimal.js';
import { types as sdkTypes } from '../../util/sdkLoader';
import { dateFromLocalToAPI } from '../../util/dates';
import { TRANSITION_REQUEST_PAYMENT, TX_TRANSITION_ACTOR_CUSTOMER } from '../../util/transaction';
import { DATE_TYPE_DATE, LINE_ITEM_SUBTOTAL } from '../../util/types';
import { unitDivisor, convertMoneyToNumber, convertUnitToSubUnit } from '../../util/currency';
import config from '../../config';
import { BookingBreakdown } from '../../components';

import css from './BookingDatesForm.module.css';

const { Money, UUID } = sdkTypes;

const estimatedTotalPrice = lineItems => {
  const numericTotalPrice = lineItems.reduce((sum, lineItem) => {
    const numericPrice = convertMoneyToNumber(lineItem.lineTotal);
    return new Decimal(numericPrice).add(sum);
  }, 0);

  // All the lineItems should have same currency so we can use the first one to check that
  // In case there are no lineItems we use currency from config.js as default
  const currency =
    lineItems[0] && lineItems[0]?.unitPrice ? lineItems[0]?.unitPrice.currency : config.currency;
  // const currency = config.currency;
  return new Money(
    convertUnitToSubUnit(numericTotalPrice.toNumber(), unitDivisor(currency)),
    currency
  );
};

// When we cannot speculatively initiate a transaction (i.e. logged
// out), we must estimate the transaction for booking breakdown. This function creates
// an estimated transaction object for that use case.
//
// We need to use FTW backend to calculate the correct line items through thransactionLineItems
// endpoint so that they can be passed to this estimated transaction.
export const calculateEstimatedTransaction = (bookingStart, bookingEnd, lineItems, payoutTotal) => {
  const now = new Date();

  const customerLineItems = lineItems.filter(
    item => item.includeFor.includes('customer') &&
      item.code !== LINE_ITEM_SUBTOTAL
  );
  // const providerLineItems = lineItems.filter(item => item.includeFor.includes('provider') && item.code !== LINE_ITEM_TAX);

  const payinTotal = estimatedTotalPrice(customerLineItems);
  // const payoutTotal = estimatedTotalPrice(providerLineItems);

  // bookingStart: "Fri Mar 30 2018 12:00:00 GMT-1100 (SST)" aka "Fri Mar 30 2018 23:00:00 GMT+0000 (UTC)"
  // Server normalizes night/day bookings to start from 00:00 UTC aka "Thu Mar 29 2018 13:00:00 GMT-1100 (SST)"
  // The result is: local timestamp.subtract(12h).add(timezoneoffset) (in eg. -23 h)

  // local noon -> startOf('day') => 00:00 local => remove timezoneoffset => 00:00 API (UTC)
  const serverDayStart =
    moment(bookingStart)
      .startOf('day')
      .toDate()

  const serverDayEnd =
    moment(bookingEnd)
      .startOf('day')
      .toDate()

  return {
    id: new UUID('estimated-transaction'),
    type: 'transaction',
    attributes: {
      createdAt: now,
      lastTransitionedAt: now,
      lastTransition: TRANSITION_REQUEST_PAYMENT,
      payinTotal,
      payoutTotal: payoutTotal ? new Money(payoutTotal.amount, payinTotal.currency) : null,
      // lineItems: isCustomer ? customerLineItems : providerLineItems,
      lineItems,
      transitions: [
        {
          createdAt: now,
          by: TX_TRANSITION_ACTOR_CUSTOMER,
          transition: TRANSITION_REQUEST_PAYMENT,
        },
      ],
    },
    booking: {
      id: new UUID('estimated-booking'),
      type: 'booking',
      attributes: {
        start: serverDayStart,
        end: bookingEnd,
        // end: moment(bookingEnd).add(1, 'days').toDate(),
      },
    },
  };
};

const CustomOfferBreakdownMaybe = props => {
  const { setLineItemsSubscription, isProvider, transaction, userRole, isCheckout } = props;
  const { unitType } = props.bookingData;
  const listing = props.listing || props?.bookingData?.listing;

  return transaction ? (
    <BookingBreakdown
      className={css.receipt}
      userRole={userRole}
      unitType={unitType}
      transaction={transaction}
      booking={transaction.booking}
      dateType={DATE_TYPE_DATE}
      listing={listing}
      setLineItemsSubscription={setLineItemsSubscription}
      isProvider={isProvider}
      hideBookingPeriod={true}
      isCheckout={isCheckout}
    />
  ) : null;
};

CustomOfferBreakdownMaybe.defaultProps = {
  setLineItemsSubscription: null,
  isProvider: false,
  transaction: null,
  userRole: null,
  isCheckout: false,
};

export default CustomOfferBreakdownMaybe;
