import config from '../../config';
import { post } from '../../util/api';
import { nightsBetween, daysBetween, dateFromAPIToLocalNoon } from '../../util/dates';
import { getSubscriptionIntervals } from '../../components/BookingBreakdown/subscription/utils';
import moment from 'moment';
import { LINE_ITEM_NIGHT } from '../../util/types';

export const acceptSubscriptionMaybe = params => {
  const { transaction, intl } = params;

  const customerId = transaction?.customer?.id?.uuid;
  const listing = transaction?.listing;
  const priceId = listing?.attributes?.publicData?.priceId;
  const authorId = listing?.author?.id?.uuid;

  const { start, end, displayStart, displayEnd } = transaction.booking.attributes;
  const unitType = config.bookingUnitType;
  const isNightly = unitType === LINE_ITEM_NIGHT;
  const localStartDate = dateFromAPIToLocalNoon(displayStart || start);
  const localEndDateRaw = dateFromAPIToLocalNoon(displayEnd || end);
  const endDay = isNightly ? moment(localEndDateRaw).subtract(1, 'days') : localEndDateRaw;

  const bookingQuantity = isNightly
    ? nightsBetween(localStartDate, new Date(endDay))
    : daysBetween(localStartDate, new Date(endDay));

  const subscriptionPayments = getSubscriptionIntervals(
    start,
    end,
    transaction,
    bookingQuantity,
    unitType,
    intl,
    listing
  );

  if (
    !subscriptionPayments ||
    !Array.isArray(subscriptionPayments) ||
    subscriptionPayments.length < 1
  ) {
    return Promise.resolve('no subscription');
  }

  const subscriptionStartDate = new Date(subscriptionPayments[0]?.start).getTime();
  const subscriptionEndDate = new Date(
    subscriptionPayments[subscriptionPayments.length - 1]?.end
  ).getTime();

  const unitPurchase = transaction.attributes.lineItems.find(
    item => item.code === unitType && !item.reversal
  );

  const quantity = unitPurchase ? unitPurchase.quantity.toString() : null;
  const limitedBookingQuantity = bookingQuantity > 31 ? 31 : bookingQuantity;
  const seatsQuantity = quantity / limitedBookingQuantity;

  const body = {
    startDate: subscriptionStartDate,
    endDate: subscriptionEndDate,
    customerId,
    priceId,
    authorId,
    listing,
    transaction,
    seatsQuantity,
  };

  return post('/api/subscribe-to-listing', body).catch(e => {
    console.log(e);
    throw e;
  });
};
