/**
 * This component will show the booking info and calculated total price.
 * I.e. dates and other details related to payment decision in receipt format.
 */
import classNames from 'classnames';
import { bool, oneOf, string } from 'prop-types';
import React from 'react';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import {
  LINE_ITEM_CUSTOMER_COMMISSION,
  LINE_ITEM_PROVIDER_COMMISSION,
  propTypes,
} from '../../util/types';
import { daysBetween, toMomentDate } from '../../util/dates';
import css from './BookingBreakdown.module.css';
import LineItemBaseOrSubscriptionFirstMonthPrice from './LineItemBaseOrSubscriptionFirstMonthPrice';
import LineItemBookingPeriod from './LineItemBookingPeriod';
import LineItemCustomerCommissionMaybe from './LineItemCustomerCommissionMaybe';
import LineItemProviderCommissionMaybe from './LineItemProviderCommissionMaybe';
import LineItemResidualPriceMaybe from './LineItemResidualPriceMaybe';
import LineItemSubTotalMaybe from './LineItemSubTotalMaybe';
import LineItemTaxMaybe from './LineItemTaxMaybe';
import LineItemTaxOnCustomerFee from './LineItemTaxOnCustomerFee';
import LineItemTaxOnProviderFee from './LineItemTaxOnProviderFee';
import LineItemTotalPrice from './LineItemTotalPrice';
import LineItemTotalProviderPayout from './LineItemTotalProviderPayout';
import LineItemUnitsMaybe from './LineItemUnitsMaybe';
import LineItemUnknownItemsMaybe from './LineItemUnknownItemsMaybe';
import SubscriptionDueLaterLineItems from './subscription/SubscriptionDueLaterLineItems';
import LineItemsRemainingTermBreakdownMaybe from './LineItemsRemainingTermBreakdownMaybe/LineItemsRemainingTermBreakdownMaybe';
import LineItemReversedMaybe from './LineItemReversedMaybe';
import LineItemRefundedMaybe from './LineItemRefundedMaybe';
import LineItemDiscountMaybe from './LineItemDiscountMaybe';
import LineItemInstallationFee from './LineItemInstallationFee';
import LineItemTaxOnBookingDeductedFromProviderPayout from './LineItemTaxOnBookingDeductedFromProviderPayout';
import LineItemSubtotalPlusTax from './LineItemSubtotalPlusTax';
import LineItemRefundNotes from './LineItemRefundNotes';

Date.prototype.addDays = function (days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};

Date.prototype.removeDays = function (days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() - days);
  return date;
};

export const BookingBreakdownComponent = props => {
  const {
    rootClassName,
    className,
    userRole,
    unitType,
    transaction,
    booking,
    intl,
    dateType,
    listing,
    setLineItemsSubscription,
    hideBookingPeriod,
    isCheckout,
  } = props;

  const isCustomer = userRole === 'customer';
  const isProvider = userRole === 'provider';

  const hasCommissionLineItem = transaction.attributes.lineItems.find(item => {
    const hasCustomerCommission = isCustomer && item.code === LINE_ITEM_CUSTOMER_COMMISSION;
    const hasProviderCommission = isProvider && item.code === LINE_ITEM_PROVIDER_COMMISSION;
    return (hasCustomerCommission || hasProviderCommission) && !item.reversal;
  });

  const classes = classNames(rootClassName || css.root, className);

  /**
   * BookingBreakdown contains different line items:
   *
   * LineItemBookingPeriod: prints booking start and booking end types. Prop dateType
   * determines if the date and time or only the date is shown
   *
   * LineItemUnitsMaybe: if he unitType is line-item/unit print the name and
   * quantity of the unit
   *
   * LineItemBaseOrSubscriptionFirstMonthPrice: prints the base price calculation for the listing, e.g.
   * "$150.00 * 2 nights $300"
   *
   * LineItemUnitPriceMaybe: prints just the unit price, e.g. "Price per night $32.00".
   * This line item is not used by default in the BookingBreakdown.
   *
   * LineItemUnknownItemsMaybe: prints the line items that are unknown. In ideal case there
   * should not be unknown line items. If you are using custom pricing, you should create
   * new custom line items if you need them.
   *
   * LineItemSubTotalMaybe: prints subtotal of line items before possible
   * commission or refunds
   *
   * LineItemRefundMaybe: prints the amount of refund
   *
   * LineItemCustomerCommissionMaybe: prints the amount of customer commission
   * The default transaction process used by FTW doesn't include the customer commission.
   *
   * LineItemCustomerCommissionRefundMaybe: prints the amount of refunded customer commission
   *
   * LineItemProviderCommissionMaybe: prints the amount of provider commission
   *
   * LineItemProviderCommissionRefundMaybe: prints the amount of refunded provider commission
   *
   * LineItemTotalPrice: prints total price of the transaction
   *
   */

  const { start, end, displayStart, displayEnd } = booking.attributes;
  const localStartDate = toMomentDate(displayStart || start);
  const localEndDateRaw = toMomentDate(displayEnd || end);
  const endDay = localEndDateRaw;

  const bookingQuantity = daysBetween(localStartDate, new Date(endDay));

  const isSubscription = bookingQuantity > 31;
  return (
    <div className={classes}>
      {!hideBookingPeriod &&
        <LineItemBookingPeriod booking={booking} unitType={unitType} dateType={dateType} />
      }
      <LineItemUnitsMaybe transaction={transaction} unitType={unitType} />

      <LineItemBaseOrSubscriptionFirstMonthPrice
        booking={booking}
        transaction={transaction}
        unitType={unitType}
        intl={intl}
        isSubscription={isSubscription}
        isCheckout={isCheckout}
      />
      <LineItemResidualPriceMaybe
        booking={booking}
        transaction={transaction}
        unitType={unitType}
        intl={intl}
        bookingQuantity={bookingQuantity}
        listing={listing}
        isCheckout={isCheckout}
      />
      <LineItemUnknownItemsMaybe transaction={transaction} isProvider={isProvider} intl={intl} />

      <LineItemInstallationFee
        transaction={transaction}
        isProvider={isProvider}
        intl={intl}
      />
      <LineItemDiscountMaybe
        transaction={transaction}
        isProvider={isProvider}
        intl={intl}
      />
      <LineItemSubTotalMaybe
        transaction={transaction}
        intl={intl}
        isCheckout={isCheckout}
      />

      <LineItemTaxMaybe
        transaction={transaction}
        intl={intl}
      />

      {isCustomer &&
        <>
          <LineItemCustomerCommissionMaybe
            transaction={transaction}
            isCustomer={isCustomer}
            intl={intl}
          />
          <LineItemTaxOnCustomerFee
            transaction={transaction}
            isCustomer={isCustomer}
            intl={intl}
          />
          <LineItemTotalPrice
            transaction={transaction}
            intl={intl}
          />
          <LineItemRefundedMaybe
            transaction={transaction}
            intl={intl}
          />
        </>
      }
      {isProvider &&
        <>
          <LineItemSubtotalPlusTax
            transaction={transaction}
            isProvider={isProvider}
            intl={intl}
          />
        </>
      }

      {isProvider &&
        <div className={css.payoutBreakdown}>
          <h6>Payout breakdown</h6>
          <LineItemTaxOnBookingDeductedFromProviderPayout
            transaction={transaction}
            isProvider={isProvider}
            intl={intl}
          />
          <LineItemProviderCommissionMaybe
            transaction={transaction}
            isProvider={isProvider}
            intl={intl}
          />
          <LineItemTaxOnProviderFee
            transaction={transaction}
            isProvider={isProvider}
            intl={intl}
          />
          <LineItemTotalProviderPayout
            transaction={transaction}
            isProvider={isProvider}
            intl={intl}
          />
          <LineItemReversedMaybe
            transaction={transaction}
            intl={intl}
          />
        </div>
      }

      <LineItemRefundNotes
        booking={booking}
      />

      <SubscriptionDueLaterLineItems
        booking={booking}
        isSubscription={isSubscription}
        bookingQuantity={bookingQuantity}
        unitType={unitType}
        transaction={transaction}
        intl={intl}
        isCustomer={isCustomer}
        isProvider={isProvider}
        listing={listing}
        setLineItemsSubscription={setLineItemsSubscription}
        isCheckout={isCheckout}
      />

      <LineItemsRemainingTermBreakdownMaybe
        transaction={transaction}
        intl={intl}
        isProvider={isProvider}
      />

      {hasCommissionLineItem ? (
        <span className={css.feeInfo}>
          <FormattedMessage id="BookingBreakdown.commissionFeeNote" />
        </span>
      ) : null}
    </div>
  );
};

BookingBreakdownComponent.defaultProps = {
  rootClassName: null,
  className: null,
  dateType: null,
  setLineItemsSubscription: null,
  hideBookingPeriod: false,
  isCheckout: false,
};

BookingBreakdownComponent.propTypes = {
  rootClassName: string,
  className: string,

  userRole: oneOf(['customer', 'provider']).isRequired,
  unitType: propTypes.bookingUnitType.isRequired,
  transaction: propTypes.transaction.isRequired,
  booking: propTypes.booking.isRequired,
  dateType: propTypes.dateType,

  // from injectIntl
  intl: intlShape.isRequired,

  setLineItemsSubscription: propTypes.func,
  hideBookingPeriod: bool,
  isCheckout: bool,
};

const BookingBreakdown = injectIntl(BookingBreakdownComponent);

BookingBreakdown.displayName = 'BookingBreakdown';

export default BookingBreakdown;
