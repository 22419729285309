import React, { useState } from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import config from '../../config';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY, propTypes } from '../../util/types';
import * as validators from '../../util/validators';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import { Button, Form, FieldCurrencyInput, FieldTextInput, Modal, TaxInfoForm } from '../../components';
import css from './EditListingPricingForm.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { manageDisableScrolling } from '../../ducks/UI.duck';

const { Money } = sdkTypes;

export const EditListingPricingFormComponent = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        form,
        values,
      } = formRenderProps;

      const unitType = config.bookingUnitType;
      const isNightly = unitType === LINE_ITEM_NIGHT;
      const isDaily = unitType === LINE_ITEM_DAY;

      const translationKey = isNightly
        ? 'EditListingPricingForm.pricePerNight'
        : isDaily
          ? 'EditListingPricingForm.pricePerDay'
          : 'EditListingPricingForm.pricePerUnit';

      const pricePerUnitMessage = intl.formatMessage({
        id: translationKey,
      });

      const pricePlaceholderMessage = intl.formatMessage({
        id: 'EditListingPricingForm.priceInputPlaceholder',
      });

      const priceRequired = validators.required(
        intl.formatMessage({
          id: 'EditListingPricingForm.priceRequired',
        })
      );
      const minPrice = new Money(config.listingMinimumPriceSubUnits, config.currency);
      const minPriceRequired = validators.moneySubUnitAmountAtLeast(
        intl.formatMessage(
          {
            id: 'EditListingPricingForm.priceTooLow',
          },
          {
            minPrice: formatMoney(intl, minPrice),
          }
        ),
        config.listingMinimumPriceSubUnits
      );
      const priceValidators = config.listingMinimumPriceSubUnits
        ? validators.composeValidators(priceRequired, minPriceRequired)
        : priceRequired;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;
      const { updateListingError, showListingsError } = fetchErrors || {};

      let initialPriceAmount = values?.price?.amount;
      const handleOnChange = formValues => {
        const prevSubPrice = values?.subscriptionPrice?.amount;
        const subscriptionPrice = formValues?.values?.subscriptionPrice;
        const subscriptionPriceAmount = subscriptionPrice?.amount;
        if (prevSubPrice != subscriptionPriceAmount) {
          const dailyPriceAmount = subscriptionPriceAmount / 31;
          const finalDailyPriceAmount = Math.round(Number(dailyPriceAmount.toFixed(2)));
          if (initialPriceAmount !== finalDailyPriceAmount) {
            form.change('price', new Money(finalDailyPriceAmount, subscriptionPrice.currency));
            form.change(
              'displayPrice',
              formatMoney(intl, new Money(finalDailyPriceAmount, subscriptionPrice.currency))
            );
            initialPriceAmount = finalDailyPriceAmount;
          }
        }
      };

      const { taxInfo } = useSelector(state => state.TaxSettingsPage);
      const [taxInfoModalOpen, setTaxInfoModalOpen] = useState(false);

      const dispatch = useDispatch();
      const onManageDisableScrolling = (componentId, disableScrolling) => {
        dispatch(manageDisableScrolling(componentId, disableScrolling));
      };

      const getDiscountFields = () => {
        const fields = new Array();
        for (let i = 0; i < 4; i++) {
          const year = i + 1;
          fields.push(
            <tr>
              <td>
                <span className={css.title}>
                  <FormattedMessage id="EditListingPricingForm.yearTermColumn" values={{ year }} />
                </span>
              </td>
              <td>&nbsp;</td>
              <td>
                <FieldTextInput
                  className={css.input}
                  name={`yearTerm${year}Discount`}
                  id={`yearTerm${year}Discount`}
                  placeholder="%"
                  type="number"
                  schemaType={'long'}
                  min={1}
                  max={50}
                />
              </td>
            </tr>
          );
        }
        return fields;
      };

      return (
        <Form onSubmit={handleSubmit} className={classes}>
          {updateListingError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingPricingForm.updateFailed" />
            </p>
          ) : null}
          {showListingsError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingPricingForm.showListingFailed" />
            </p>
          ) : null}

          <FormSpy onChange={handleOnChange} />
          <FieldCurrencyInput
            className={css.subscriptionPriceField}
            id="subscriptionPrice"
            name="subscriptionPrice"
            label="Monthly price"
            placeholder="Type here..."
            type="textarea"
            currencyConfig={config.currencyConfig}
            validate={priceValidators}
          />

          <FieldCurrencyInput
            id="price"
            name="price"
            className={css.priceInputHidden}
            label={pricePerUnitMessage}
            placeholder={pricePlaceholderMessage}
            currencyConfig={config.currencyConfig}
            // validate={priceValidators}
            disabled={true}
          />

          <FieldTextInput
            className={css.priceInput}
            label={pricePerUnitMessage}
            name="displayPrice"
            id="displayPrice"
            placeholder="Calculated from the subscription price"
            type="text"
            disabled={true}
          />

          <div className={css.yearTermDiscount}>
            <div className={css.rowUnsetMarginLR}>
              <table>
                <thead>
                  <tr>
                    <td colspan="2">
                      <h4 className={css.headingTitle}>
                        <FormattedMessage id="EditListingPricingForm.discountsTitle" />
                      </h4>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {getDiscountFields()}
                </tbody>
              </table>
            </div>
          </div>

          <div className={css.taxInfoSection}>
            <div className={css.rowUnsetMarginLR}>
              <div className={css.col12}>
                <h6 className={css.title}>
                  <FormattedMessage id="EditListingPricingForm.taxInfoTitle" />
                </h6>
              </div>
            </div>
            <div className={css.rowUnsetMarginLR}>
              <div className={css.colTaxInfo}>
                <div className={css.field}>
                  <label>
                    <FormattedMessage id="EditListingPricingForm.taxId" />
                  </label>
                  <div>
                    {taxInfo?.taxId ? taxInfo.taxId : '--'}
                  </div>
                </div>
              </div>
              <div className={css.colTaxInfo}>
                <div className={css.field}>
                  <label>
                    <FormattedMessage id="EditListingPricingForm.legalBusinessName" />
                  </label>
                  <div>
                    {taxInfo?.legalBusinessName ? taxInfo.legalBusinessName : '--'}
                  </div>
                </div>
              </div>
              <div className={css.colTaxInfo}>
                <button onClick={() => setTaxInfoModalOpen(true)}
                  className={css.editBtn}
                  type="button">
                  <FormattedMessage id="EditListingPricingForm.editTaxInfo" />
                </button>
              </div>
            </div>
            <Modal
              id="EditListingPricingForm.taxInfoModal"
              isOpen={taxInfoModalOpen}
              onClose={() => setTaxInfoModalOpen(false)}
              usePortal={false}
              onManageDisableScrolling={onManageDisableScrolling}>
              <TaxInfoForm setTaxInfoModalOpen={setTaxInfoModalOpen} />
            </Modal>
          </div>

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form >
      );
    }}
  />
);

EditListingPricingFormComponent.defaultProps = { fetchErrors: null };

EditListingPricingFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingPricingFormComponent);
