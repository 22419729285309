import { useSelector } from "react-redux";
import { connectStripeAccount, createStripeAccountLink, createStripeAccountLoginLink } from "../../util/api";
import { useState } from "react";
import StripeConnectAccountStatusBox from "../StripeConnectAccountStatusBox/StripeConnectAccountStatusBox";
import css from './StripeExpressStatusBox.module.scss';
import { propTypes } from "../../util/types";
import Button from "../Button/Button";

const StripeExpressStatusBox = props => {
    const { transactionId, isCustomOffer } = props;

    const state = useSelector(state => state);

    const [accountLinkInProgress, setAccountLinkInProgress] = useState(false)
    const [dashboardLinkInProgress, setDashboardLinkInProgress] = useState(false)
    const [accountLinkInError, setAccountLinkError] = useState(null)

    const stripeExpress = state?.StripeExpressPayoutPage?.stripeExpress;
    const payouts_enabled = stripeExpress?.payouts_enabled;

    const onConnnectStripeAccount = () => {
        setAccountLinkInProgress(true)
        connectStripeAccount({ transactionId: transactionId?.uuid })
            .then(link => {
                if (link) {
                    window.open(link, '_self');
                } else {
                    setAccountLinkInProgress(false)
                }
            }).catch(error => {
                console.error(error)
                setAccountLinkInProgress(false)
                setAccountLinkError(true)
            })
    }

    const getStripeConnectExpressDashboardLink = path => {
        setDashboardLinkInProgress(true)
        createStripeAccountLoginLink()
            .then(link => {
                if (link) {
                    window.open(link, '_blank');
                    setDashboardLinkInProgress(false)
                } else {
                    setDashboardLinkInProgress(false)
                }
            }).catch(error => {
                console.error(error)
                setDashboardLinkInProgress(false)
            })
    }

    const getStripeConnectExpressAccountLink = () => {
        setAccountLinkInProgress(true)
        createStripeAccountLink()
            .then(link => {
                if (link) {
                    window.open(link, '_self');
                    setAccountLinkInProgress(false)
                } else {
                    setAccountLinkInProgress(false)
                }
            }).catch(error => {
                console.error(error)
                setAccountLinkInProgress(false)
                setAccountLinkError(true)
            })
    }

    return (
        <>
            {!stripeExpress &&
                <StripeConnectAccountStatusBox
                    type="verificationNotStarted"
                    inProgress={accountLinkInProgress}
                    onGetStripeConnectAccountLink={() => onConnnectStripeAccount()}
                />
            }
            {stripeExpress && payouts_enabled &&
                <>
                    {!isCustomOffer &&
                        <>
                            <h6>Stripe Account</h6>
                            <StripeConnectAccountStatusBox
                                type="verificationSuccess"
                                inProgress={accountLinkInProgress}
                                onGetStripeConnectAccountLink={() => getStripeConnectExpressAccountLink('settings')}
                            />
                            <Button
                                inProgress={dashboardLinkInProgress}
                                onClick={() => getStripeConnectExpressDashboardLink()} className={css.stripeConnectCtaButton}>
                                View Stripe Dashboard
                            </Button>
                        </>
                    }
                </>
            }
            {stripeExpress && !payouts_enabled &&
                <StripeConnectAccountStatusBox
                    type="verificationNeeded"
                    inProgress={accountLinkInProgress}
                    onGetStripeConnectAccountLink={() => getStripeConnectExpressDashboardLink()}
                />
            }
        </>
    )
}

StripeExpressStatusBox.defaultProps = {
    transactionId: null,
    isCustomOffer: false,
};

StripeExpressStatusBox.propTypes = {
    rootClassName: propTypes.uuid,
    isCustomOffer: propTypes.bool,
};

export default StripeExpressStatusBox;