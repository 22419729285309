import React from 'react';
import { FormattedDate, FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { propTypes, LINE_ITEM_SUBSCRIPTION_FIRST_MONTH, LINE_ITEM_DAY } from '../../util/types';
import css from './BookingBreakdown.module.css';
import subscriptionCss from './subscription/Subscription.module.scss';
import dateFormatOptions from '../../util/dateFormatOptions';

const LineItemResidualPriceMaybe = props => {
  const {
    transaction,
    unitType,
    intl,
  } = props;

  const subscriptionFirstMonth = transaction.attributes.lineItems.find(
    item => item.code === LINE_ITEM_SUBSCRIPTION_FIRST_MONTH && !item.reversal
  );
  const unitPurchase = transaction.attributes.lineItems.find(
    item => item.code === LINE_ITEM_DAY && !item.reversal
  );

  if (!subscriptionFirstMonth) {
    return null;
  }

  if (!unitPurchase) {
    return null;
  }

  const translationKey = 'BookingBreakdown.baseUnitDay';

  const { units, seats, start, end } = unitPurchase;
  const unitPrice = unitPurchase ? formatMoney(intl, unitPurchase.unitPrice) : null;
  const total = unitPurchase ? formatMoney(intl, unitPurchase.lineTotal) : null;

  return total ? (
    <>
      <div className={subscriptionCss.basePriceLabelWrapper}>
        <p>
          <FormattedDate value={start} {...dateFormatOptions} /> -{' '}
          <FormattedDate value={end} {...dateFormatOptions} />
        </p>
      </div>
      <div className={css.lineItem}>
        <span className={css.itemLabel}>
          <FormattedMessage
            id={translationKey}
            values={{ unitPrice, units, seats }}
          />
        </span>
        <span className={css.itemValue}>{total}</span>
      </div>
    </>
  ) : null;
};

LineItemResidualPriceMaybe.defaultProps = {
  transaction: null,
  unitType: null,
  intl: null,
}

LineItemResidualPriceMaybe.propTypes = {
  transaction: propTypes.transaction.isRequired,
  unitType: propTypes.bookingUnitType.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemResidualPriceMaybe;
